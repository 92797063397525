<template>
  <div>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title class="primary--text">
            Account
            <v-spacer />
            <v-btn color="primary" :to="{ name: 'venue.account.edit', params: { tab: 0 } }">Edit</v-btn>
          </v-card-title>

          <v-card-text>
            <v-list>
              <v-list-item>
                <v-list-item-avatar size="80">
                  <v-img v-if="user.image" :src="$getProfile200Image(user._id, user.image)" />
                  <v-img v-if="!user.image" src="@/assets/images/promotor.svg" />
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-if="user.firstName">
                    {{ `${user.firstName} ${user.lastName}` }}
                  </v-list-item-title>

                  <v-list-item-subtitle>
                    {{ user.role | capitalize }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-list dense>
              <v-list-item>
                <v-list-item-title class="d-flex">
                  <div class="w-50 text-bold">First name</div>
                  <div>{{ user.firstName }}</div>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title class="d-flex">
                  <div class="w-50 text-bold">Last name</div>
                  <div>{{ user.lastName }}</div>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title class="d-flex">
                  <div class="w-50 text-bold">Email</div>
                  <div>{{ user.email }}</div>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title class="d-flex">
                  <div class="w-50 text-bold">Phone</div>
                  <div>{{ user.phone }}</div>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title class="d-flex">
                  <div class="w-50 text-bold">Address</div>
                  <div>{{ user.address }}</div>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title class="d-flex">
                  <div class="w-50 text-bold">City</div>
                  <div>{{ user.city }}</div>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title class="d-flex">
                  <div class="w-50 text-bold">State/County</div>
                  <div>{{ user.state }}</div>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title class="d-flex">
                  <div class="w-50 text-bold">Postcode</div>
                  <div>{{ user.postcode }}</div>
                </v-list-item-title>
              </v-list-item>

              <v-list-item>
                <v-list-item-title class="d-flex">
                  <div class="w-50 text-bold">Country</div>
                  <div>{{ user.country }}</div>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { UserService } from '@services';

export default {
  data() {
    return {
      user: {},
      socialProfiles: {},
      payouts: {},
    };
  },
  created() {
    this.getCurrentUser();
  },
  methods: {
    async getCurrentUser() {
      this.user = await UserService.me();
    },
  },
};
</script>
